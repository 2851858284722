<template>
  <div class="report">
    <div class="card-report-container">
      <div class="step">
        <span>1</span>
      </div>
      <div class="report-container">
        <header>
          <h5>Scope / Alcance</h5>
        </header>
        <div class="scope-container">
          <p>
            Los dominios o aplicaciones que no esten enlistada, el reporte sera
            conciderado como invalido
          </p>
          <div class="dropdown">
            <select name="cars" id="cars">
              <option value="volvo">Volvo</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-report-container">
      <div class="step">
        <span>2</span>
      </div>
      <div class="report-container">
        <header>
          <h5>Vulnerabilidad</h5>
        </header>
        <div class="scope-container">
          <p>
            Dependiendo de la vulnerabilidad seleccionada se considerara la
            severidad del reporte
          </p>
          <div class="dropdown">
            <select name="cars" id="cars">
              <option value="volvo">Volvo</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-report-container">
      <div class="step">
        <span>3</span>
      </div>
      <div class="report-container">
        <header>
          <h5>Detalles</h5>
        </header>
        <div class="scope-container">
          <p>
            Describe lo mejor posible los pasos para reproducir la
            vulnerabilidad encontrada
          </p>
          <div class="detail">
            <textarea name="report-detail" id="" cols="30" rows="10"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card-report-container">
      <div class="step">
        <span>4</span>
      </div>
      <div class="report-container">
        <header>
          <h5>Adjuntos / POC</h5>
        </header>
        <div class="scope-container">
          <p>
            Adjunta cualquier prueba o evidencia de lo detallado en el paso
            anterior para asi poder corroborar y asignar una severidad mas
            exacta a tu reporte
          </p>
          <div class="detail">
            <input type="file" />
          </div>
        </div>
      </div>
    </div>
    <p class="report-note">
      *Los reportes se revisaran por parte de JAMA Security y el equipo de
      Seguridad de la empresa, el tiempo estimado de respuesta es de 1 mes a 3
      meses dependiendo de la informacion proporcionada y la severidad de la
      vulnerabilidad
    </p>
    <div class="report-button">
      <button>Reportar Vulnerabilidad</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "report",
};
</script>

<style lang="scss">
.report {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  .card-report-container {
    display: flex;
    align-items: center;
    .step {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #111cf0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
      }
    }
    .report-container {
      width: 1100px;
      border: 1px solid #e9e9e9;
      margin: 0 0 20px 20px;
      header {
        h5 {
          margin: 0;
        }
        padding: 20px;
        background: #f7f8f9;
      }
      .scope-container {
        padding: 20px;
        textarea {
          width: 100%;
        }
      }
    }
  }
  .report-note {
    width: 1100px;
    margin: 40px auto;
    font-size: 14px;
  }
  .report-button {
    display: flex;
    justify-content: flex-start;
    width: 1100px;
    button {
      padding: 20px;
      border-radius: 4px;
      border: none;
      background: #222ac3;
      cursor: pointer;
      color: white;
    }
  }
}
</style>
